import React from 'react';
import Layout from '../components/Layout';
import Checkout from '../components/Checkout';

const CheckoutPage = () => {
  return (
    <Layout removeLinesBackground={true} noIndex={true}>
      <main>
        <Checkout />
      </main>
    </Layout>
  );
};

export default CheckoutPage;
